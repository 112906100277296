import {
  base,
  budget,
  purchase,
  purchaseMethod,
  repair,
  scrap,
  accept,
  assets
} from './views'

export default [
  {
    path: 'home',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: 'base',
    name: 'base',
    component: () => import('../views/base/index'),
    children: base
  },
  {
    path: 'budget',
    name: 'budget',
    component: () => import('../views/budget/router'),
    children: budget
  },
  {
    path: 'purchase',
    name: 'purchase',
    component: () => import('../views/purchase/router'),
    children: purchase
  },
  {
    path: 'purchase_method',
    name: 'purchase_method',
    component: () => import('../views/purchase_method/router'),
    children: purchaseMethod
  },
  {
    path: 'repair',
    name: 'repair',
    component: () => import('../views/repair/index'),
    children: repair
  },
  {
    path: 'scrap',
    name: 'scrap',
    component: () => import('../views/scrap/index'),
    children: scrap
  },
  {
    path: 'accept',
    name: 'accept',
    component: () => import('../views/accept/index'),
    children: accept
  },
  {
    path: 'assets',
    name: 'assets',
    component: () => import('../views/assets/index'),
    children: assets
  }
]
