import sysEnv from '@/env/env'

export const allSys = [{key: 'ucenter', label: '用户中心'}, {key: 'hrms', label: '业务系统'}]

export const activeSysList = allSys.filter(v => v.key === 'ucenter' || (sysEnv.sysList || []).includes(v.key))

export const otherSys = activeSysList.filter(v => v.key !== 'ucenter')

export const getOtherSysPages = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let router = []
    if (key === 'hrms') {
      router = require('hrms/src/router/pages.js').default
    } else if (key === 'internalcontrol') {
      router = require('internalcontrol/src/router/pages.js').default
    }
    dealRouterData(router, key)
    let pRouter = {
      path: '/' + key + '_p',
      name: key + '_p',
      component: () => import('./router/router'),
      children: router
    }
    data.push(pRouter)
    i ++
  }
  return data
}

export const getOtherSysAppViews = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let router = []
    if (key === 'hrms') {
      router = require('hrms/src/router/app_views.js').default
    } else if (key === 'internalcontrol') {
      router = require('internalcontrol/src/router/app_views.js').default
    }
    dealRouterData(router, key)
    let pRouter = {
      path: '/' + key,
      name: key,
      component: () => import('./router/router'),
      children: router
    }
    data.push(pRouter)
    i ++
  }
  return data
}

export const getOtherSysStoreModules = () => {
  let data = {}
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let files = {}
    if (key === 'hrms') {
      files = require.context('hrms/src/store/modules', false, /\.js$/)
    } else if (key === 'internalcontrol') {
      files = require.context('internalcontrol/src/store/modules', false, /\.js$/)
    }
    files.keys().map(key => {
      data[key] = files(key).default
    })
    i ++
  }
  return data
}

export const getOtherSysConfig = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let config = []
    if (key === 'hrms') {
      config = require('hrms/src/config/sys.js').default.sysConfig
    } else if (key === 'internalcontrol') {
      config = require('internalcontrol/src/config/sys.js').default.sysConfig
    }
    data = data.concat(config)
    i ++
  }
  return data
}

export const getOtherSysSelectData = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let config = []
    if (key === 'hrms') {
      config = require('hrms/src/config/sys.js').default.selectData
    } else if (key === 'internalcontrol') {
      config = require('internalcontrol/src/config/sys.js').default.selectData
    }
    data = data.concat(config)
    i ++
  }
  return data
}

export const getOtherSysRolesConfig = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let config = []
    if (key === 'hrms') {
      config = require('hrms/src/config/roles.js').default
    } else if (key === 'internalcontrol') {
      config = require('internalcontrol/src/config/roles.js').default
    }
    data = data.concat(config)
    i ++
  }
  return data
}

export const getOtherSysMenusConfig = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let menus = []
    if (key === 'hrms') {
      menus = require('hrms/src/config/menus/index.js').default
    } else if (key === 'internalcontrol') {
      menus = require('internalcontrol/src/config/menus/index.js').default
    }
    dealMenuData(menus, key)
    data = data.concat(menus)
    i ++
  }
  return data
}

export const getOtherSysFunsConfig = () => {
  let data = []
  let i = 0
  while (i < otherSys.length) {
    let key = otherSys[i].key
    let funs = key === 'hrms' ? require('hrms/src/fun/index.js').default : []
    if (key === 'hrms') {
      funs = require('hrms/src/fun/index.js').default
    } else if (key === 'internalcontrol') {
      funs = require('internalcontrol/src/fun/index.js').default
    }
    funs = JSON.parse(JSON.stringify(funs))
    funs.forEach(v => {
      v.routerNames = v.routerNames.map(n => n.includes('/') ? n : (key + '.' + n))
    })
    data = data.concat(funs)
    i ++
  }
  return data
}

const dealRouterData = (routerData, sys) => {
  routerData.forEach(v => {
    v.name = sys + '.' + v.name
    if (v.children && v.children.length > 0) {
      dealRouterData(v.children, sys)
    }
  })
}

const dealMenuData = (menus, sys) => {
  menus.forEach(v => {
    v.sys = sys
    if (v.url && ['web', 'wap'].includes(v.clientType)) {
      v.url = sys + '.' + v.url
    }
    if (v.children && v.children.length > 0) {
      dealMenuData(v.children, sys)
    }
  })
}

export const delUserDeal = async (vm, userId) => {
  if (otherSys.map(v => v.key).includes('hrms')) {
    return await require('hrms/src/config/other.js').delUser(vm, userId)
  }
  return false
}

export const updateUserDeal = async (vm, user) => {
  if (otherSys.map(v => v.key).includes('hrms')) {
    return await require('hrms/src/config/other.js').updateUser(vm, user)
  }
  return false
}