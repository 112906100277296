import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth'
import User from './modules/user'
import BaseData from './modules/base_data'
import Theme from './modules/theme'

import {
  getOtherSysStoreModules
} from '@/sys'

const modules = {
  Auth,
  User,
  BaseData,
  Theme,
  ...getOtherSysStoreModules()
}

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules,
  strict: debug
})
