export default {
  selectData: [{
    key: 'worker_clan_list',
    label: '员工民族可选值',
    remark: '员工民族可选值',
    defaultValue: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '僳僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_political_status_list',
    label: '员工政治面貌可选值',
    remark: '员工政治面貌可选值',
    defaultValue: ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_identity_list',
    label: '员工身份可选值',
    remark: '员工身份可选值',
    defaultValue: ['应届毕业生', '社会人员'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_job_type',
    label: '人员类别',
    remark: '员工管理人员类别可选值',
    defaultValue: ['借调人员劳务派遣', '退休返聘', '退休人员', '特设岗位人员', '非特设岗位人员', '自建池人员', '员额池人员', '周转池人员', '无分类人员'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_zg_status',
    label: '人员在岗状态',
    remark: '员工管理人员在岗状态可选值',
    defaultValue: ['见习', '试用期', '聘用期间', '借用借调', '辞职辞退', '开除', '退休'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'employment_form',
    label: '入职方式',
    remark: '员工管理入职方式可选值',
    defaultValue: ['公开招聘', '政策性安置', '任命', '交流'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_education_list',
    label: '员工学历可选值',
    remark: '员工学历可选值',
    defaultValue: ['博士研究生', '硕士研究生', '本科', '大专', '中专', '高中', '初中', '小学'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_edu_degree',
    label: '学位可选值',
    remark: '学位可选值',
    defaultValue: ['博士', '硕士', '学士', '无'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_resign_list',
    label: '离职方式可选值',
    remark: '离职方式可选值',
    defaultValue: ['退休', '开除', '辞职', '辞退', '交流', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'worker_edu_type_list',
    label: '教育形式可选值',
    remark: '教育形式可选值',
    defaultValue: ['全日制', '在职教育'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'rew_type_list',
    label: '奖励类型可选值',
    remark: '奖励类型可选值',
    defaultValue: ['授予荣誉称号', '嘉奖', '记功', '记大功', '晋级', '国家技术发明奖', '国家科学技术进步奖', '部委科学技术奖', '省（自治区、直销市、特别行政区）科学进步奖', '省（部）级以下的科学技术奖励', '国际和国外奖励', '聘书'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'rew_level_list',
    label: '授予荣誉称号的级别可选值',
    remark: '授予荣誉称号的级别可选值',
    defaultValue: ['国家级荣誉称号', '省（自治区、直辖市）级荣誉称号', '部（委）级荣誉称号', '地（市、厅、局）级荣誉称号', '区（县、局）级荣誉称号', '系统内部荣誉称号', '基层企事业单位荣誉称号', '国外授予的荣誉称号', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'pun_type_list',
    label: '处分种类',
    remark: '处分种类',
    defaultValue: ['警告', '记过', '降低岗位等级或撤职', '开除'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'train_type_list',
    label: '培训类型可选值',
    remark: '培训类型可选值',
    defaultValue: ['岗前培训', '在岗培训', '转岗培训', '专项培训'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'train_jypx_list',
    label: '教育培训性质可选值',
    remark: '教育培训性质可选值',
    defaultValue: ['政治理论', '专业知识', '学历学位教育', '培训'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'train_lg_list',
    label: '培训离岗状态可选值',
    remark: '培训离岗状态可选值',
    defaultValue: ['全脱产（离岗）', '半脱产（半离岗）', '不脱产（不离岗）'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'train_cxdw_list',
    label: '从学单位类别',
    remark: '从学单位类别',
    defaultValue: ['医院', '函授学校', '全日制高等学校', '管理干部学院', '研究（院）所', '研究中心', '中央党校', '省委党校', '地、市委党校', '县委党校', '乡镇党校', '培训中心', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'jobtitle_type_list',
    label: '上岗记录岗位专业可选值',
    remark: '上岗记录岗位专业可选值',
    defaultValue: ['医师', '护理', '药师', '检验', '技师', '计算机', '会计', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'agreement_type_list',
    label: '合同类型可选值',
    remark: '合同类型可选值',
    defaultValue: ['劳动合同', '事业单位聘用合同', '劳务派遣合同', '劳务合同', '退休返聘劳动合同', '临时聘用合同'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'agreement_signed_type_list',
    label: '合同签订方式可选值',
    remark: '合同签订方式可选值',
    defaultValue: ['首签', '续签'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'certificate_type_list',
    label: '专业技术资格证书证书专业可选值',
    remark: '专业技术资格证书证书专业可选值',
    defaultValue: ['医师', '护理', '药师', '检验', '技师', '计算机', '会计'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'zyjsryzylb',
    label: '专业技术人员专业类别',
    remark: '专业技术人员专业类别可选值',
    defaultValue: ['医师', '护师', '技师', '药师', '其他'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'job_title_type',
    label: '岗位类型',
    remark: '岗位管理岗位类型可选值',
    defaultValue: [{key: '管理岗位', label: '管理岗位'}, {key: '专业技术岗位', label: '专业技术岗位'}, {key: '工勤技能岗位', label: '工勤技能岗位'}]
  },
  {
    key: 'qualification_certificate_type',
    label: '资格证书类型',
    remark: '职业资格证书类型',
    defaultValue: ['医师资格证书', '药师资格证', '健康管理师资格证', '公共营养师资格证'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'practicing_certificate_type',
    label: '执业证书类型',
    remark: '执业证书类型',
    defaultValue: ['医师执业证书', '护士执业证书', '药师执业证书', '助理执业医师证书'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  },
  {
    key: 'doc_send_level_list',
    label: '公文来文级别',
    remark: '公文来文级别',
    defaultValue: ['国家、省级市级', '县机关', '县卫健委', '本院'].map(v => {
      return {
        key: v,
        label: v
      }
    })
  }],
  sysConfig: [{
    key: 'envName',
    label: '环境名称',
    remark: '当前环境名称',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  },
  {
    key: 'env',
    label: '环境',
    remark: '当前环境',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  },
  {
    key: 'jobTitleAllWorker',
    label: '全员可上岗',
    remark: '员工上岗管理可选职工范围(在编/全部)',
    defaultValue: false,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'defaultOrgName',
    label: '所属机构名称默认值',
    remark: '员工所属机构名称默认值，新增员工时指定该默认值',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  },
  {
    key: 'orgNameDisabled',
    label: '所属机构名称是否可以编辑',
    remark: '员工管理所属机构名称是否可以编辑',
    defaultValue: true,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'needOrgName2',
    label: '是否需要第二机构名称',
    remark: '员工管理是否需要第二机构名称',
    defaultValue: false,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'defaultOrgName2',
    label: '第二机构名称默认值',
    remark: '员工所属第二机构名称默认值，新增员工时指定该默认值',
    defaultValue: '',
    editConfig: {
      type: 'input'
    }
  },
  {
    key: 'orgName2Disabled',
    label: '第二机构名称是否可编辑',
    remark: '员工管理第二机构名称是否可编辑',
    defaultValue: true,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'workerApplyEditType1',
    label: '员工信息申请变更情况1',
    remark: '员工信息申请变更情况1: “个人身份”、“是否特殊人才”、“离职方式” 不可维护',
    defaultValue: false,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'workerTableType1',
    label: '员工信息表格展示情况1',
    remark: '员工信息表格展示情况1: 表格中增加：第一学历毕业学校、第一学历专业、最高学历毕业学校。',
    defaultValue: false,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'workerShowSelfId',
    label: '员工信息维护中个人身份是否展示',
    remark: '员工信息维护中个人身份是否展示，默认展示',
    defaultValue: true,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  },
  {
    key: 'workerShowDefaultOrg',
    label: '员工信息维护中默认展示机构等级是否展示',
    remark: '员工信息维护默认展示机构等级是否展示，默认展示',
    defaultValue: true,
    editConfig: {
      type: 'switch',
      label: ['否', '是']
    }
  }]
}
