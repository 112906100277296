export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: `/basis/worker_certificate`,
      method: 'GET'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: [{
      path: `/basis/worker_certificate/my`,
      method: 'GET'
    },
    {
      path: '/basis/worker/my',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [{
      path: `/basis/worker_certificate/my_org`,
      method: 'GET'
    },
    {
      path: '/basis/worker/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '查看本机构及其子机构的',
    apis: [{
      path: `/basis/worker_certificate/my_org_contain_child`,
      method: 'GET'
    },
    {
      path: '/basis/worker/my_org_contain_child',
      method: 'GET'
    }]
  },
  add: {
    funKey: 'add',
    name: '新增',
    apis: [{
      path: '/basis/worker_certificate',
      method: 'POST'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  update: {
    funKey: 'edit',
    name: '修改',
    apis: [{
      path: '/basis/worker_certificate/{id}',
      method: 'PUT'
    },
    {
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/worker_certificate/{id}',
      method: 'DELETE'
    }]
  },
  data_file: {
    funKey: 'data_file',
    name: '扫描件'
  },
  getDataFile: {
    funKey: 'getDataFile',
    name: '获取扫描件'
  },
  updateDataFile: {
    funKey: 'updateDataFile',
    name: '上传扫描件',
    apis: [{
      path: '/basis/worker_certificate/{id}',
      method: 'PUT'
    }]
  },
  downDataFile: {
    funKey: 'downDataFile',
    name: '下载扫描件'
  },
  delDataFile: {
    funKey: 'delDataFile',
    name: '删除扫描件',
    apis: [{
      path: '/basis/worker_certificate/{id}',
      method: 'PUT'
    }]
  }
}