export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构的',
    apis: [{
      path: '/internalcontrol/assets/my_org',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  label: {
    funKey: 'label',
    name: '资产标签',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'GET'
    }]
  },
  ledger: {
    funKey: 'ledger',
    name: '台账',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets/{id}',
      method: 'GET'
    },
    {
      path: '/internalcontrol/budget/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_batch/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_method_batch/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    },
    {
      path: '/internalcontrol/acceptance',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_in',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_repair',
      method: 'GET'
    }]
  },
  add_all: {
    funKey: 'add_all',
    name: '新增',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'POST'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    },
    {
      path: '/ucenter/org',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase',
      method: 'GET'
    }]
  },
  batch_add_all: {
    funKey: 'batch_add_all',
    name: '批量新增',
    apis: [{
      path: '/internalcontrol/assets',
      method: 'POST'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  edit_all: {
    funKey: 'edit_all',
    name: '维护',
    apis: [{
      path: '/internalcontrol/assets/{id}',
      method: 'PUT'
    },
    {
      path: '/ucenter/user',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细',
    apis: [{
      path: '/ucenter/user',
      method: 'GET'
    },
    {
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_type/tree',
      method: 'GET'
    },
    {
      path: '/internalcontrol/goods',
      method: 'GET'
    },
    {
      path: '/internalcontrol/purchase_contract',
      method: 'GET'
    }]
  },
  repair: {
    funKey: 'repair',
    name: '维修',
    apis: [{
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/budget/detail',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_repair_apply',
      method: 'POST'
    }]
  },
  scrap: {
    funKey: 'scrap',
    name: '报废',
    apis: [{
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_scrap_apply',
      method: 'POST'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/internalcontrol/assets/{id}',
      method: 'DELETE'
    }]
  }
}