import {
  menuRequest,
  funRequest,
  roleRequest,
  userRequest,
  orgRequest,
  orgTypeRequest,
  sysConfigRequest
} from '@/api'

import {
  tools
} from '@/fmlib'

import {
  getOtherSysConfig,
  getOtherSysSelectData,
  activeSysList
} from '@/sys'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const state = {
  // 正在加载的数据
  baseDataLoading: {},
  // 已加载数据
  baseDataLoaded: {},
  // 菜单
  menuList: [],
  // 功能
  funList: [],
  // 角色
  roleList: [],
  // 系统
  allSysList: activeSysList,
  // 可用系统
  authSysList: [],
  // 当前系统
  currentSys: null,
  // 菜单类型
  menuTypeList: [{key: 'menu', label: '菜单'}, {key: 'sys_page', label: '系统页面'}, {key: 'out_page', label: '外部链接'}],
  // 客户端类型
  clientTypeList: [{key: 'web', label: '电脑'}, {key: 'wap', label: '手机'}, {key: 'app', label: 'APP'}, {key: 'pda', label: 'PDA'}],
  // 当前客户端类型
  clientType: navigator.userAgent.match(/Android|iPhone|iPad|iPod/) === null ? 'web' : 'wap',
  // user
  userList: [],
  // userGroup
  userGroup: [],
  // userStatus
  // userStatusList: [{key: 'active', label: '激活'}, {key: 'blocked', label: '阻止'}, {key: 'deleted', label: '已删除'}, {key: 'inactive', label: '作废'}, {key: 'newreg', label: '缺省'}],
  userStatusList: [{key: 'active', label: '激活'}, {key: 'inactive', label: '作废'}, {key: 'newreg', label: '未激活'}],
  // orgList
  orgList: [],
  // orgTree
  orgTree: [],
  // orgType
  orgTypeList: [],
  selectDatas: {},
  sysConfig: {}
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.baseDataLoaded[dataKey]) && !getters.baseDataLoading[dataKey]) {
    commit('updateBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateBaseDataLoaded', dataKey)
    commit('updateBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.baseDataLoading[dataKey]) {
    while (getters.baseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  baseDataLoading: state => state.baseDataLoading,
  baseDataLoaded: state => state.baseDataLoaded,
  menuList: state => state.menuList,
  allSysList: state => state.allSysList,
  authSysList: state => state.authSysList,
  currentSys: state => state.currentSys,
  funList: state => state.funList,
  roleList: state => state.roleList,
  menuTypeList: state => state.menuTypeList,
  clientTypeList: state => state.clientTypeList,
  clientType: state => state.clientType,
  userList: state => state.userList,
  userGroup: state => state.userGroup,
  userStatusList: state => state.userStatusList,
  orgList: state => state.orgList,
  orgTree: state => state.orgTree,
  orgTypeList: state => state.orgTypeList,
  selectDatas: state => state.selectDatas,
  sysConfig: state => state.sysConfig
}

const actions = {
  async loadUserList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'userList', request: userRequest.get, mutation: 'setUserList', dealSourceData})
  },
  setUserList ({ commit }, data) {
    commit('setUserList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'userList')
  },
  async loadRoleList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.forEach(v => v.name = v.name === 'admin' ? '超级管理员' : v.name)
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'roleList', request: roleRequest.getBase, mutation: 'setRoleList', dealSourceData})
  },
  setRoleList ({ commit }, data) {
    commit('setRoleList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'roleList')
  },
  async loadMenuList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = tools.treeToList(dataList, 'children')
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'menuList', request: menuRequest.get, mutation: 'setMenuList', dealSourceData})
  },
  setMenuList ({ commit }, data) {
    commit('setMenuList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'menuList')
  },
  async loadFunList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['funKey', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'funList', request: funRequest.get, mutation: 'setFunList', dealSourceData})
  },
  setFunList ({ commit }, data) {
    commit('setFunList', dealData(data, 'id', ['funKey', 'name']))
    commit('updateBaseDataLoaded', 'funList')
  },
  async loadOrgList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = dataList.filter(v => !v.sys)
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgList', request: orgRequest.getAll, mutation: 'setOrgList', dealSourceData})
  },
  setOrgList ({ commit }, data) {
    commit('setOrgList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'orgList')
  },
  async loadOrgTree ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = dataList.filter(v => !v.sys)
      return dataList
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgTree', request: orgRequest.getAllTree, mutation: 'setOrgTree', dealSourceData})
  },
  setOrgTree ({ commit }, data) {
    commit('setOrgTree', data)
    commit('updateBaseDataLoaded', 'orgTree')
  },
  async loadOrgTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.sort((a, b) => a.sortNum - b.sortNum)
      return dealData(dataList, 'id', ['level', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'orgTypeList', request: orgTypeRequest.get, mutation: 'setOrgTypeList', dealSourceData})
  },
  setOrgTypeList ({ commit }, data) {
    commit('setOrgTypeList', dealData(data, 'id', ['level', 'name']))
    commit('updateBaseDataLoaded', 'orgTypeList')
  },
  async loadSelectDatas ({ commit, getters }, reLoad) {
    if (reLoad || Object.keys(getters.selectDatas).length === 0) {
      let res = await sysConfigRequest.get({type: 'selectData'})
      let dataMap = {}
      getOtherSysSelectData().forEach(v => {
        let value = res.find(v1 => v1.configKey === v.key)
        if (value) {
          value = JSON.parse(value.value)
          if (value.length > 0) {
            value = value.map(v1 => {
              return {
                key: v1.value,
                label: v1.label
              }
            })
          }
        }
        dataMap[v.key] = value && value.length > 0 ? value : v.defaultValue
      })
      commit('setSelectDatas', dataMap)
    }
    return getters.selectDatas
  },
  async loadSysConfig ({ commit, getters }) {
    let res = await sysConfigRequest.get({type: 'sysConfig'})
    let dataMap = {}
    getOtherSysConfig().forEach(v => {
      let value = res.find(v1 => v1.configKey === v.key)
      if (value && value.value) {
        value = JSON.parse(value.value)
      } else {
        value = v.defaultValue
      }
      dataMap[v.key] = value
    })
    commit('setSysConfig', dataMap)
    return getters.sysConfig
  },
  async loadUserGroup ({ commit }) {
    let res = await sysConfigRequest.get({type: 'userGroup'})
    commit('setUserGroup', res)
  },
  updateAuthSysList ({ getters, commit }, keys) {
    commit('setAuthSysList', getters.allSysList.filter(v => keys.includes(v.key)))
    // if (getters.authSysList.length > 0) {
    //   dispatch('setCurrentSys', getters.authSysList[0].key)
    // }
  },
  setCurrentSys ({ commit }, key) {
    commit('setCurrentSys', key)
  }
}

const mutations = {
  updateBaseDataLoading (state, {key, value}) {
    state.baseDataLoading[key] = value
  },
  updateBaseDataLoaded (state, key) {
    state.baseDataLoaded[key] = true
  },
  setMenuList (state, data) {
    state.menuList = data
  },
  setFunList (state, data) {
    state.funList = data
  },
  setRoleList (state, data) {
    state.roleList = data
  },
  setUserList (state, data) {
    state.userList = data
  },
  setOrgList (state, data) {
    state.orgList = data
  },
  setOrgTypeList (state, data) {
    state.orgTypeList = data
  },
  setOrgTree (state, data) {
    state.orgTree = data
  },
  setSelectDatas (state, data) {
    state.selectDatas = data
  },
  setSysConfig (state, data) {
    state.sysConfig = data
  },
  setAuthSysList (state, data) {
    state.authSysList = data
  },
  setCurrentSys (state, data) {
    state.currentSys = data
  },
  setUserGroup (state, data) {
    state.userGroup = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
