export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/status_manage/config',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构申请的',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply',
      method: 'GET'
    },
    {
      path: '/internalcontrol/assets_scrap_apply/my_org',
      method: 'GET'
    },
    {
      path: '/internalcontrol/status_manage/config',
      method: 'GET'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '明细',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply',
      method: 'GET'
    }]
  },
  back: {
    funKey: 'back',
    name: '状态切换',
    apis: [{
      path: '/internalcontrol/assets_scrap_apply/switch/{id}',
      method: 'POST'
    }]
  }
}