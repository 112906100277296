export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/ucenter/org/all',
      method: 'GET'
    },
    {
      path: '/basis/activity',
      method: 'GET'
    }]
  }
}