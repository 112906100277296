export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/official_document',
      method: 'GET'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  new: {
    funKey: 'new',
    name: '新增',
    apis: [{
      path: '/basis/official_document',
      method: 'POST'
    },
    {
      path: '/basis/worker/all',
      method: 'GET'
    }]
  },
  edit_all: {
    funKey: 'edit_all',
    name: '编辑',
    apis: [{
      path: '/basis/official_document',
      method: 'POST'
    },
    {
      path: '/basis/official_document/detail',
      method: 'GET'
    }]
  },
  over: {
    funKey: 'over',
    name: '归档',
    apis: [{
      path: '/basis/official_document/{id}',
      method: 'PUT'
    }]
  },
  cover: {
    funKey: 'cover',
    name: '打印封面',
    apis: [{
      path: '/basis/official_document/detail',
      method: 'GET'
    }]
  },
  back: {
    funKey: 'back',
    name: '继续处理',
    apis: [{
      path: '/basis/official_document/back/{id}',
      method: 'PUT'
    }]
  },
  detail: {
    funKey: 'detail',
    name: '查看',
    apis: [{
      path: '/basis/official_document/detail',
      method: 'GET'
    }]
  },
  del: {
    funKey: 'del',
    name: '删除',
    apis: [{
      path: '/basis/official_document/{id}',
      method: 'DELETE'
    }]
  }
}