import {
  workerRequest,
  jobTitleRequest,
  certificateRequest
} from '../../api'

import {
  tools
} from '@/fmlib'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const state = {
  // 正在加载的数据
  hrmsBaseDataLoading: {},
  // 已加载数据
  hrmsBaseDataLoaded: {},
  // 考核状态
  appraisalStatusList: [{key: 'plan', label: '等待'}, {key: 'doing', label: '进行中'}, {key: 'over', label: '已完成'}, {key: 'void', label: '已取消'}],
  // 考核类型
  appraisalTypeList: [{key: 'scoring', label: '打分'}, {key: 'vote', label: '投票'}, {key: 'question', label: '考题'}],
  // 考核分组类型
  appraisalGroupTypeList: [{key: 'becheck', label: '被考核'}, {key: 'check', label: '考核'}, {key: 'host', label: '主持'}, {key: 'screen', label: '大屏'}, {key: 'other', label: '其他'}],
  // 考核分组明细状态
  appraisalGroupDetailStatusList: [{key: 'wait', label: '等待'}, {key: 'ready', label: '准备中'}, {key: 'doing', label: '进行中'}, {key: 'over', label: '已完成'}, {key: 'giveup', label: '弃权'}],
  // 活动类型字段类型
  activityTypeKeyTypeList: [{key: 'text', label: '文本'}, {key: 'longText', label: '长文本'}, {key: 'number', label: '数字'}, {key: 'date', label: '日期'}, {key: 'dataTime', label: '时间'}, {key: 'file', label: '文件'}],
  // 活动状态
  activityStatusList: [{key: 'wait', label: '未开展'}, {key: 'doing', label: '开展中'}, {key: 'end', label: '已开展'}],
  // workerList
  workerList: [],
  // workerListMy
  workerListMy: [],
  // workerListMyOrg
  workerListMyOrg: [],
  // workerListMyOrgContainChild
  workerListMyOrgContainChild: [],
  // allWorkerList
  allWorkerList: [],
  // jobTitle
  jobTitleList: [],
  // certificate
  certificateList: [],
  // certificateType
  certificateTypeList: [{key: 'doctor_qua', label: '医师资格证书'}, {key: 'doctor_pra', label: '执业证书'}, {key: 'pro_qua', label: '专业技术资格证书'}, {key: 'pro_hire', label: '专业技术职务聘任证书'}]
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.hrmsBaseDataLoaded[dataKey]) && !getters.hrmsBaseDataLoading[dataKey]) {
    commit('updateHrmsBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateHrmsBaseDataLoaded', dataKey)
    commit('updateHrmsBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.hrmsBaseDataLoading[dataKey]) {
    while (getters.hrmsBaseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  hrmsBaseDataLoading: state => state.hrmsBaseDataLoading,
  hrmsBaseDataLoaded: state => state.hrmsBaseDataLoaded,
  workerUserList: state => state.allWorkerList.filter(v => v.data.userId).map(v => {
    return {
      key: v.data.userId,
      label: v.data.name,
      data: v.data
    }
  }),
  currentWorkerId: state => state.workerListMy.length > 0 ? state.workerListMy[0].data.id : null,
  workerList: state => state.workerList,
  workerListMy: state => state.workerListMy,
  workerListMyOrg: state => state.workerListMyOrg,
  workerListMyOrgContainChild: state => state.workerListMyOrgContainChild,
  allWorkerList: state => state.allWorkerList,
  appraisalStatusList: state => state.appraisalStatusList,
  appraisalTypeList: state => state.appraisalTypeList,
  appraisalGroupTypeList: state => state.appraisalGroupTypeList,
  appraisalGroupDetailStatusList: state => state.appraisalGroupDetailStatusList,
  activityTypeKeyTypeList: state => state.activityTypeKeyTypeList,
  activityStatusList: state => state.activityStatusList,
  jobTitleList: state => state.jobTitleList,
  certificateList: state => state.certificateList,
  certificateTypeList: state => state.certificateTypeList,
  hrmsSelectDatas: state => state.hrmsSelectDatas
}

const actions = {
  async loadWorkerList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'workerList', request: workerRequest.get, mutation: 'setWorkerList', dealSourceData})
  },
  setWorkerList ({ commit }, data) {
    commit('setWorkerList', dealData(data, 'id', 'name'))
    commit('updateHrmsBaseDataLoaded', 'workerList')
  },
  async loadWorkerListMy ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'workerListMy', request: workerRequest.getMy, mutation: 'setWorkerListMy', dealSourceData})
  },
  async loadCurrentWorkerId ({ getters, dispatch }, reLoad) {
    await dispatch('loadWorkerListMy', reLoad)
    return getters.currentWorkerId
  },
  setWorkerListMy ({ commit }, data) {
    commit('setWorkerList', dealData(data, 'id', 'name'))
    commit('updateHrmsBaseDataLoaded', 'workerListMy')
  },
  async loadWorkerListMyOrg ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'workerListMyOrg', request: workerRequest.getMyOrg, mutation: 'setWorkerListMyOrg', dealSourceData})
  },
  setWorkerListMyOrg ({ commit }, data) {
    commit('setWorkerListMyOrg', dealData(data, 'id', 'name'))
    commit('updateHrmsBaseDataLoaded', 'workerListMyOrg')
  },
  async loadWorkerListMyOrgContainChild ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'workerListMyOrgContainChild', request: workerRequest.getMyOrgContainChild, mutation: 'setWorkerListMyOrgContainChild', dealSourceData})
  },
  setWorkerListMyOrgContainChild ({ commit }, data) {
    commit('setWorkerListMyOrgContainChild', dealData(data, 'id', 'name'))
    commit('updateHrmsBaseDataLoaded', 'workerListMyOrgContainChild')
  },
  async loadWorkerUserList ({ getters, dispatch }, reLoad) {
    await dispatch('loadAllWorkerList', reLoad)
    return getters.workerUserList
  },
  async loadAllWorkerList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['code', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'allWorkerList', request: workerRequest.getAll, mutation: 'setAllWorkerList', dealSourceData})
  },
  setAllWorkerList ({ commit }, data) {
    commit('setAllWorkerList', dealData(data, 'id', 'name'))
    commit('updateHrmsBaseDataLoaded', 'allWorkerList')
  },
  async loadJobTitleList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.sort((a, b) => {
        return a.lelel - b.lelel
      })
      dataList.sort((a, b) => {
        return getters.selectDatas['job_title_type'].map(v => v.key).indexOf(a.type) - getters.selectDatas['job_title_type'].map(v => v.key).indexOf(b.type)
      })
      return dealData(dataList, 'id', ['type', 'title'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'jobTitleList', request: jobTitleRequest.get, mutation: 'setJobTitleList', dealSourceData})
  },
  setJobTitleList ({ commit }, data) {
    commit('setJobTitleList', dealData(data, 'id', ['type', 'title']))
    commit('updateHrmsBaseDataLoaded', 'jobTitleList')
  },
  async loadCertificateList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'title')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'certificateList', request: certificateRequest.get, mutation: 'setCertificateList', dealSourceData})
  },
  setCertificateList ({ commit }, data) {
    commit('setCertificateList', dealData(data, 'id', 'title'))
    commit('updateHrmsBaseDataLoaded', 'certificateList')
  }
}

const mutations = {
  updateHrmsBaseDataLoading (state, {key, value}) {
    state.hrmsBaseDataLoading[key] = value
  },
  updateHrmsBaseDataLoaded (state, key) {
    state.hrmsBaseDataLoaded[key] = true
  },
  setAllWorkerList (state, data) {
    state.allWorkerList = data
  },
  setWorkerList (state, data) {
    state.workerList = data
  },
  setWorkerListMy (state, data) {
    state.workerListMy = data
  },
  setWorkerListMyOrg (state, data) {
    state.workerListMyOrg = data
  },
  setWorkerListMyOrgContainChild (state, data) {
    state.workerListMyOrgContainChild = data
  },
  setJobTitleList (state, data) {
    state.jobTitleList = data
  },
  setCertificateList (state, data) {
    state.certificateList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
