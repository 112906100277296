// 资产盘点
export default {
  get: {
    funKey: 'get',
    name: '获取',
    apis: [
      { path: '/ucenter/org/all', method: 'GET' },
      { path: '/internalcontrol/assets', method: 'GET' }
    ]
  },
  scanRecord: {
    funKey: 'scanRecord',
    name: '盘点入库',
    apis: [
      { path: '/internalcontrol/scan_record', method: 'POST' },
      { path: '/internalcontrol/assets/{id}', method: 'PUT' },
      { path: '/internalcontrol/assets', method: 'GET' }
    ]
  },
}