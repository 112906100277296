import {
  getOtherSysAppViews
} from '@/sys'


export const appViews = [
  ...getOtherSysAppViews(),
  {
    path: 'index',
    name: 'index',
    component: () => import('@/views/app/nullPage')
  },
  {
    path: 'null_menu',
    name: 'null_menu',
    component: () => import('@/views/app/nullMenu')
  },
  {
    path: 'sys',
    name: 'sys',
    component: () => import('@/views/sys'),
    children: [
      {
        path: 'user',
        name: 'sys.user',
        component: () => import('@/views/sys/user'),
      },
      {
        path: 'role',
        name: 'sys.role',
        component: () => import('@/views/sys/role'),
        props: {
          roleType: 'role'
        }
      },
      {
        path: 'menu',
        name: 'sys.menu',
        component: () => import('@/views/sys/menu'),
      },
      {
        path: 'fun',
        name: 'sys.fun',
        component: () => import('@/views/sys/fun')
      },
      {
        path: 'placard',
        name: 'sys.placard',
        component: () => import('@/views/sys/placard')
      }
    ]
  },
  {
    path: 'org',
    name: 'org',
    component: () => import('@/views/org'),
    children: [
      {
        path: 'manage',
        name: 'org.manage',
        component: () => import('@/views/org/manage'),
      },
      {
        path: 'dw',
        name: 'org.dw',
        component: () => import('@/views/org/dw'),
      },
      {
        path: 'ls',
        name: 'org.ls',
        component: () => import('@/views/org/ls'),
      },
      {
        path: 'gl',
        name: 'org.gl',
        component: () => import('@/views/org/gl'),
      },
      {
        path: 'type',
        name: 'org.type',
        component: () => import('@/views/org/type'),
      },
      {
        path: 'info',
        name: 'org.info',
        component: () => import('@/views/org/info'),
      },
      {
        path: 'position',
        name: 'org.position',
        component: () => import('@/views/sys/role'),
        props: {
          roleType: 'position'
        }
      }
    ]
  },
  {
    path: 'config',
    name: 'config',
    component: () => import('@/views/config'),
    children: [
      {
        path: 'sys',
        name: 'config.sys',
        component: () => import('@/views/config/sys'),
      },
      {
        path: 'select',
        name: 'config.select',
        component: () => import('@/views/config/select'),
      },
      {
        path: 'user_group',
        name: 'config.user_group',
        component: () => import('@/views/config/userGroup'),
      }
    ]
  }
]
