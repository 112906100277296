import assetsManage from './assetsManage'
import assetsCheck from './assetsCheck'
import assetsIn from './assetsIn'
import assetsRepair from './assetsRepair'
import assetsRepairApply from './assetsRepairApply'
import assetsRepairApplyRecord from './assetsRepairApplyRecord'
import assetsScrapApply from './assetsScrapApply'
import assetsScrapApplyRecord from './assetsScrapApplyRecord'

import assetsType from './assetsType'
import goods from './goods'
import budgetManage from './budget_manage'
import budget from './budget'
import purchase from './purchase'
import purchaseContract from './purchaseContract'
import purchaseImplement from './purchaseImplement'
import purchaseDetail from './purchaseDetail'
import purchaseSp from './purchaseSp'
import home from './home'

import purchaseBatch from './purchaseBatch'
import purchaseBatchMy from './purchaseBatchMy'
import purchaseMethodBatchManage from './purchaseMethodBatchManage'
import purchaseMethodBatch from './purchaseMethodBatch'
import bill from './bill'
import accept from './accept'
import acceptMy from './acceptMy'

import pdaAssets from './pda/assets'
import pdaInventory from './pda/inventory'
import pdaInvoice from './pda/invoice'
import pdaPandian from './pda/pandian'
import pdaRepair from './pda/repair'

import appBudget from './app/budget'
import appPurchase from './app/purchase'
import appPurchaseWay from './app/purchaseWay'
import budgetDetail from './budget_detail'
import budgetHome from './budget_home'
import budgetInfo from './budget_info'

export default [{
  routerNames: ['home'],
  funs: home
},
{
  routerNames: ['accept.manage'],
  funs: accept
},
{
  routerNames: ['accept.my'],
  funs: acceptMy
},
{
  routerNames: ['purchase_method.index'],
  funs: purchaseMethodBatchManage
},
{
  routerNames: ['purchase_method.handle'],
  funs: purchaseMethodBatch
},
{
  routerNames: ['purchase.index'],
  funs: purchaseBatch
},
{
  routerNames: ['purchase.handle'],
  funs: purchaseBatchMy
},
{
  routerNames: ['budget.manage'],
  funs: budgetManage
},
{
  routerNames: ['budget.detail_list'],
  funs: budgetDetail
},
{
  routerNames: ['budget.home'],
  funs: budgetHome
},
{
  routerNames: ['budget.batch_detail'],
  funs: budgetInfo
},
{
  routerNames: ['budget.handle'],
  funs: budget
},
{
  routerNames: ['budget.purchase_sp'],
  funs: purchaseSp
},
{
  routerNames: ['budget.purchase_detail'],
  funs: purchaseDetail
},
{
  routerNames: ['purchase.contract'],
  funs: purchaseContract
},
{
  routerNames: ['purchase.implement'],
  funs: purchaseImplement
},
{
  routerNames: ['budget.purchase'],
  funs: purchase
},
{
  routerNames: ['assets.manage', 'assets.manage_gy', 'assets.manage_dw', '/pages/assets/index'],
  funs: assetsManage
},
{
  routerNames: ['assets.check', '/pages/pandian/index'],
  funs: assetsCheck
},
{
  routerNames: ['assets.in', '/pages/inventory/index'],
  funs: assetsIn
},
{
  routerNames: ['repair.manage', 'assets.repair', '/pages/repair/index', '/pages/repair/manage'],
  funs: assetsRepair
},
{
  routerNames: ['repair.apply', '/pages/repair/apply'],
  funs: assetsRepairApply
},
{
  routerNames: ['repair.apply_record', '/pages/repair/apply/record'],
  funs: assetsRepairApplyRecord
},
{
  routerNames: ['scrap.apply'],
  funs: assetsScrapApply
},
{
  routerNames: ['scrap.record'],
  funs: assetsScrapApplyRecord
},
{
  routerNames: ['basis.assets_type'],
  funs: assetsType
},
{
  routerNames: ['budget.budget'],
  funs: budget
},
{
  routerNames: ['basis.goods'],
  funs: goods
},
{
  routerNames: ['base.bill'],
  funs: bill
}, {
  routerNames: ['/pages/assets/index'],
  funs: pdaAssets
}, {
  routerNames: ['/pages/pandian/index'],
  funs: pdaPandian
}, {
  routerNames: ['/pages/inventory/index'],
  funs: pdaInventory
}, {
  routerNames: ['/pages/repair/index'],
  funs: pdaRepair
}, {
  routerNames: ['/pages/invoice/index'],
  funs: pdaInvoice
}, {
  routerNames: ['/pages/budget/index?type=handler', '/pages/budget/index?type=manage'],
  funs: appBudget
}, {
  routerNames: ['/pages/purchase/index?type=handler', '/pages/purchase/index?type=manage'],
  funs: appPurchase
}, {
  routerNames: ['/pages/purchase/way/index?type=handler', '/pages/purchase/way/index?type=manage'],
  funs: appPurchaseWay
}]
