export const worker = [{
  path: 'inforate',
  name: 'worker.inforate',
  component: () => import('../views/worker/infoRate')
},
{
  path: 'worker',
  name: 'worker.worker',
  component: () => import('../views/worker/worker')
},
{
  path: 'mail',
  name: 'worker.mail',
  component: () => import('../views/worker/workerMail')
},
{
  path: 'rdsqs',
  name: 'worker.rdsqs',
  component: () => import('../views/worker/rdsqs'),
},
{
  path: 'fzdx',
  name: 'worker.fzdx',
  component: () => import('../views/worker/fzdx'),
},
{
  path: 'rdjjfz',
  name: 'worker.rdjjfz',
  component: () => import('../views/worker/rdjjfz'),
},
{
  path: 'ybdy',
  name: 'worker.ybdy',
  component: () => import('../views/worker/ybdy'),
},
{
  path: 'zsdy',
  name: 'worker.zsdy',
  component: () => import('../views/worker/zsdy'),
},
{
  path: 'zwbgjl',
  name: 'worker.zwbgjl',
  component: () => import('../views/worker/zwbgjl'),
},
{
  path: 'dfjn',
  name: 'worker.dfjn',
  component: () => import('../views/worker/dfjn'),
}]

const getActivityDj = () => {
  let ids = [1,2,3,4,5,6,7,8,9,10,11]
  let datas = []
  ids.forEach(v => {
    datas.push({
      path: 'manage_' + v,
      props: { activityTypeId: v },
      name: 'activity.manage.' + v,
      component: () => import('../views/activity/manage')
    })
    datas.push({
      path: 'my_' + v,
      props: { activityTypeId: v },
      name: 'activity.my.' + v,
      component: () => import('../views/activity/my')
    })
    datas.push({
      path: 'child_' + v,
      props: { activityTypeId: v },
      name: 'activity.child.' + v,
      component: () => import('../views/activity/child')
    })
  })
  return datas
}

export const activity = [
  {
    path: 'type',
    name: 'activity.type',
    component: () => import('../views/activity/type'),
  },
  {
    path: 'type_jia',
    name: 'activity.type_jia',
    component: () => import('../views/activity/typeJia'),
  },
  {
    path: 'manage',
    name: 'activity.manage',
    component: () => import('../views/activity/manage'),
  },
  {
    path: 'manage_jia',
    name: 'activity.manage_jia',
    component: () => import('../views/activity/manageJia'),
  },
  {
    path: 'my',
    name: 'activity.my',
    component: () => import('../views/activity/my'),
  },
  {
    path: 'detail',
    name: 'activity.detail',
    component: () => import('../views/activity/detail')
  },
  {
    path: 'show',
    name: 'activity.show',
    component: () => import('../views/activity/show'),
  },
  {
    path: 'show_jia',
    name: 'activity.show_jia',
    component: () => import('../views/activity/showJia'),
  }
].concat(getActivityDj())


export const askLeave = [
  {
    path: 'apply',
    name: 'ask_leave.apply',
    component: () => import('../views/askleave/apply'),
  },
  {
    path: 'apply_assign',
    name: 'ask_leave.apply_assign',
    component: () => import('../views/askleave/applyAssign'),
  },
  {
    path: 'apply_record',
    name: 'ask_leave.apply_record',
    component: () => import('../views/askleave/applyRecord'),
  },
  {
    path: 'record',
    name: 'ask_leave.record',
    component: () => import('../views/askleave/record')
  },
  {
    path: 'my',
    name: 'ask_leave.my',
    component: () => import('../views/askleave/my')
  },
  {
    path: 'home',
    name: 'ask_leave.home',
    component: () => import('../views/askleave/home')
  },
  {
    path: 'switch_config',
    name: 'ask_leave.switch_config',
    component: () => import('../views/askleave/switchConfig')
  }
]

export const base = [
  {
    path: 'job_title',
    name: 'base.job_title',
    component: () => import('../views/base/jobTitle'),
  },
  {
    path: 'certificate',
    name: 'base.certificate',
    component: () => import('../views/base/certificate'),
  },
  {
    path: 'status',
    name: 'base.status',
    component: () => import('../views/status/index'),
  }
]

export const appraisal = [
  {
    path: 'manage',
    name: 'appraisal.manage',
    component: () => import('../views/appraisal/manage'),
  },
  {
    path: 'ready',
    name: 'appraisal.ready',
    component: () => import('../views/appraisal/ready'),
  },
  {
    path: 'doing',
    name: 'appraisal.doing',
    component: () => import('../views/appraisal/doing'),
  },
  {
    path: 'checking',
    name: 'appraisal.checking',
    component: () => import('../views/appraisal/checking'),
  },
  {
    path: 'question',
    name: 'appraisal.question',
    component: () => import('../views/appraisal/question'),
  },
  {
    path: 'vote',
    name: 'appraisal.vote',
    component: () => import('../views/appraisal/vote'),
  },
  {
    path: 'my',
    name: 'appraisal.my',
    component: () => import('../views/appraisal/my'),
  }
]

export const doc = [
  {
    path: 'manage',
    name: 'doc.manage',
    component: () => import('../views/doc/manage')
  },
  {
    path: 'handle',
    name: 'doc.handle',
    component: () => import('../views/doc/handle')
  },
  {
    path: 'detail',
    name: 'doc.detail',
    component: () => import('../views/doc/detail')
  }
]

export const issues = [
  {
    path: 'manage',
    name: 'issues.manage',
    component: () => import('../views/issues/manage')
  },
  {
    path: 'handle',
    name: 'issues.handle',
    component: () => import('../views/issues/handle')
  },
  {
    path: 'detail',
    name: 'issues.detail',
    component: () => import('../views/issues/detail')
  }
]

export const org = [
  {
    path: 'show',
    name: 'org.show',
    component: () => import('../views/org/show')
  },
  {
    path: 'detail',
    name: 'org.detail',
    component: () => import('../views/org/detail')
  }
]

export const count = [
  {
    path: 'base',
    name: 'count.base',
    component: () => import('../views/count/base')
  },
  {
    path: 'home',
    name: 'count.home',
    component: () => import('../views/count/home')
  },
  {
    path: 'count',
    name: 'count.count',
    component: () => import('../views/count/count')
  }
]

export const resume = [
  {
    path: 'index',
    name: 'resume.index',
    component: () => import('../views/resume/index')
  },
  {
    path: 'details',
    name: 'resume.details',
    component: () => import('../views/resume/details'),
    children: [
      {
        path: 'base',
        name: 'resume.details.base',
        component: () => import('../views/resume/details/base'),
      },
      {
        path: 'dj',
        name: 'resume.details.dj',
        component: () => import('../views/resume/details/dj'),
      },
      {
        path: 'family',
        name: 'resume.details.family',
        component: () => import('../views/resume/details/family'),
      },
      {
        path: 'all',
        name: 'resume.details.all',
        component: () => import('../views/resume/details/all'),
      }
    ]
  },
  {
    path: 'preview',
    name: 'resume.preview',
    component: () => import('../views/resume/preview')
  },
  {
    path: 'print',
    name: 'resume.print',
    component: () => import('../views/resume/print')
  }
]

export const report = [
  { path: 'nucleic/print', name: 'report.nucleic.print', component: () => import('../views/report/nucleic/print') },
  { path: 'nucleic/data', name: 'report.nucleic.data', component: () => import('../views/report/nucleic/data') },
  { path: 'nucleic/result', name: 'report.nucleic.result', component: () => import('../views/report/nucleic/result') },
  { path: 'nucleic/counts', name: 'report.nucleic.counts', component: () => import('../views/report/nucleic/counts') }
]

export const analysis = [
  { path: 'age', name: 'analysis.age', component: () => import('../views/analysis/age') },
  { path: 'medical', name: 'analysis.medical', component: () => import('../views/analysis/medical') }
]

export const evaluation = [
  {
    path: 'index',
    name: 'evaluation.index',
    component: () => import('../views/evaluation/index')
  },
  {
    path: 'details',
    name: 'evaluation.details',
    component: () => import('../views/evaluation/details'),
    children: [
      {
        path: 'draw',
        name: 'evaluation.details.draw',
        props: { type: 'draw' },
        component: () => import('../views/evaluation/details/draw')
      },
      {
        path: 'opinion',
        name: 'evaluation.details.opinion',
        props: { type: 'opinion' },
        component: () => import('../views/evaluation/details/draw')
      },
      {
        path: 'approve',
        name: 'evaluation.details.approve',
        props: { type: 'approve' },
        component: () => import('../views/evaluation/details/approve')
      }
    ]
  }
]
