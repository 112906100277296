import {
  appViews
} from './views'

import {
  getOtherSysPages
} from '@/sys'

export default [
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: appViews
  },
  {
    path: '/login',
    name: 'login',
    component: () => /iphone|ios|android|ipad/i.test(navigator.userAgent) ? import('@/views/login/mobile') : import('@/views/login/index')
  },
  ...getOtherSysPages()
]
