// pad端: pda
export default [{
  'name': '资产管理',
  'icon': 'tags-fill',
  'url': '/pages/assets/index',
  'roles': ['assets', 'nk'],
  'clientType': 'pda'
},
{
  'name': '资产盘点',
  'icon': 'bookmark-fill',
  'url': '/pages/pandian/index',
  'roles': ['assets'],
  'clientType': 'pda'
},
{
  'name': '出入库',
  'icon': 'checkmark-circle-fill',
  'url': '/pages/inventory/index',
  'roles': ['assets'],
  'clientType': 'pda'
},
{
  'name': '维修',
  'icon': 'setting-fill',
  'url': '/pages/repair/index',
  'roles': ['assets'],
  'clientType': 'pda'
},
{
  'name': '发票管理',
  'icon': 'order',
  'url': '/pages/invoice/index',
  'roles': ['bill', 'nk'],
  'clientType': 'pda'
}]