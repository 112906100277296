export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [{
      path: '/basis/worker',
      method: 'GET'
    }]
  },
  getMy: {
    funKey: 'getMy',
    name: '查看我的',
    apis: [{
      path: '/basis/worker/my',
      method: 'GET'
    }]
  },
  getMyOrg: {
    funKey: 'getMyOrg',
    name: '查看本机构下的',
    apis: [{
      path: '/basis/worker/my_org',
      method: 'GET'
    }]
  },
  getMyOrgContainChild: {
    funKey: 'getMyOrgContainChild',
    name: '查看本机构及其子机构的',
    apis: [{
      path: '/basis/worker/my_org_contain_child',
      method: 'GET'
    }]
  }
}