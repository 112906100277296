import {
  localstorageHelper
} from '@/fmlib'

const state = {
  userInfo: {},
  orgRoles: [],
  currentOrgRole: null,
  overviewRole: localStorage.getItem('overviewRole') === 'true',
  currentMenus: []
}

const getFirstMenu = (menus) => {
  let i = 0
  let findMenu = null
  while (findMenu == null && i < menus.length) {
    if (menus[i].url) {
      findMenu = menus[i]
    } else if (menus[i].children) {
      findMenu = getFirstMenu(menus[i].children)
    }
    i ++
  }
  return findMenu
}

const getters = {
  userInfo: state => state.userInfo,
  orgRoles: state => state.orgRoles,
  currentOrgRole: state => state.currentOrgRole,
  currentUserId: state => {
    return state.userInfo ? state.userInfo.id : null
  },
  currentOrgId: state => {
    return state.currentOrgRole ? state.currentOrgRole.orgId : []
  },
  currentRoleId: state => {
    return state.currentOrgRole ? state.currentOrgRole.roleId : []
  },
  currentMenus: state => state.currentMenus,
  currentFuns: state => {
    return state.currentOrgRole ? state.currentOrgRole.funs : []
  },
  currentFunKeys: state => {
    return (state.currentOrgRole ? state.currentOrgRole.funs : []).map(v => v.funKey)
  },
  currentDataAuthority: state => {
    return state.currentOrgRole ? state.currentOrgRole.authorities : []
  },
  userFirstPage: (state) => {
    let goMenu = null
    let menus = state.currentMenus.filter(v => v.clientType === 'web')
    let hrmsMenus = menus.filter(v => v.sys === 'hrms')
    if (hrmsMenus.length > 0) {
      goMenu = getFirstMenu(hrmsMenus)
    }
    if (goMenu === null) {
      let icMenus = menus.filter(v => v.sys === 'internalcontrol')
      if (icMenus.length > 0) {
        goMenu = getFirstMenu(icMenus)
      }
    }
    if (goMenu === null) {
      goMenu = getFirstMenu(menus)
    }
    return {name: goMenu && goMenu.url ? goMenu.url : 'null_menu'}
  },
  overviewRole: state => state.overviewRole
}

const actions = {
  setUserData ({ dispatch }, data) {
    dispatch('setUserInfo', {
      nickname: data.nickname,
      name: data.name,
      id: data.id
    })
    dispatch('setOrgRoles', data.orgRoles || [])
  },
  getSysUserFirstPage ({ getters }) {
    let goMenu = getFirstMenu(getters.currentMenus.filter(v => v.sys === getters.currentSys))
    return {name: goMenu && goMenu.url ? goMenu.url : 'null_menu'}
  },
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
  },
  setOrgRoles ({ commit, dispatch }, data) {
    data.forEach(v => {
      v.label = (v.orgName ? v.orgName + ' ' : '') + (v.roleName ? v.roleName : '')
    })
    commit('setOrgRoles', data)
    if (data.length > 0) {
      let orgRoleId = localstorageHelper.getData('orgRoleId')
      dispatch('setCurrentOrgRole', (data.find(v => v.id === orgRoleId) || data[0]).id)
    } else {
      dispatch('setCurrentOrgRole', null)
    }
  },
  setCurrentOrgRole ({ commit, getters, dispatch }, orgRoleId) {
    let orgRole = getters.orgRoles.find(v => v.id === orgRoleId)
    if (orgRole && orgRole.id) {
      localstorageHelper.updateData('roleId', orgRoleId)
    }
    commit('setCurrentOrgRole', orgRole)
    // let currentMenus = getters.currentOrgRole ? getters.currentOrgRole.menus.filter(v => v.sys === state.currentSys && v.clientType === getters.clientType) : []
    let currentMenus = getters.currentOrgRole && getters.currentOrgRole.menus ? getters.currentOrgRole.menus.filter(v => v.clientType === getters.clientType) : []
    dispatch('updateAuthSysList', currentMenus.map(v => v.sys))
    commit('setCurrentMenus', currentMenus)
  },
  setOverviewRole ({ commit }, val) {
    commit('setOverviewRole', val)
  }
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setOrgRoles (state, data) {
    state.orgRoles = data
  },
  setCurrentOrgRole (state, data) {
    state.currentOrgRole = data
  },
  setCurrentMenus (state, data) {
    state.currentMenus = data
  },
  setOverviewRole (state, data) {
    state.overviewRole = data
    localStorage.setItem('overviewRole', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
