// 电脑浏览器端: web
const allRoles = ['self', 'rs','yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs']

const rolesNoSelf = ['rs', 'yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz']

const tmpWorker = [{
  'self': ['getMy', 'history', 'output', 'allinfo']
},
{
  'yz': ['get', 'history', 'output', 'allinfo']
},
{
  'fyz': ['get', 'history', 'output', 'allinfo']
},
{
  'sj': ['get', 'history', 'output', 'allinfo']
},
{
  'fsj': ['get', 'history', 'output', 'allinfo']
},
{
  'kz': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'fkz': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'zr': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'fzr': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'hsz': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'fhsz': ['getMyOrg', 'history', 'output', 'allinfo']
},
{
  'ys': ['getMy', 'history', 'output', 'allinfo']
},
{
  'hs': ['getMy', 'history', 'output', 'allinfo']
}, 'rs']

const tmpWorkerIdCard = [{
  'self': ['getMy', 'idcard_file']
},
{
  'yz': ['get', 'idcard_file']
},
{
  'fyz': ['get', 'idcard_file']
},
{
  'sj': ['get', 'idcard_file']
},
{
  'fsj': ['get', 'idcard_file']
},
{
  'kz': ['getMyOrg', 'idcard_file']
},
{
  'fkz': ['getMyOrg', 'idcard_file']
},
{
  'zr': ['getMyOrg', 'idcard_file']
},
{
  'fzr': ['getMyOrg', 'idcard_file']
},
{
  'hsz': ['getMyOrg', 'idcard_file']
},
{
  'fhsz': ['getMyOrg', 'idcard_file']
},
{
  'ys': ['getMy', 'idcard_file']
},
{
  'hs': ['getMy', 'idcard_file']
}, 'rs']

const tmpAskLeaveHome = [{
  'self': ['base']
},
{
  'ys': ['base']
},
{
  'hs': ['base']
}, ...rolesNoSelf]

const tmpAskLeave = [{
  'self': ['get', 'detail', 'data_file', 'getDataFile']
},
{
  'yz': ['get', 'detail', 'data_file', 'getDataFile']
},
{
  'fyz': ['get', 'detail', 'data_file', 'getDataFile']
},
{
  'sj': ['get', 'detail', 'data_file', 'getDataFile']
},
{
  'fsj': ['get', 'detail', 'data_file', 'getDataFile']
}, 'rs']

const tmpNdkh = [{
  'self': ['get', 'getDetails', 'my', 'mycheck', 'myindex']
},
{
  'ys': ['get', 'getDetails', 'my', 'mycheck', 'myindex']
},
{
  'hs': ['get', 'getDetails', 'my', 'mycheck', 'myindex']
},
{
  'yz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'fyz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'sj': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'fsj': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'kz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'fkz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'zr': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'fzr': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'hsz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'fhsz': ['get', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
},
{
  'rs': ['get', 'del', 'update', 'add', 'status', 'getDetails', 'orgcheck', 'leadcheck', 'othercheck']
}]

const tmp1 = [{
  'self': ['getMy', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'yz': ['get', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'fyz': ['get', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'sj': ['get', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'fsj': ['get', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'kz': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'fkz': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'zr': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'fzr': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'hsz': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'fhsz': ['getMyOrg', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'ys': ['getMy', 'data_file', 'getDataFile', 'downDataFile']
},
{
  'hs': ['getMy', 'data_file', 'getDataFile', 'downDataFile']
}, 'rs']

const tmp2 = [{
  'self': ['getMy']
},
{
  'yz': ['get']
},
{
  'fyz': ['get']
},
{
  'sj': ['get']
},
{
  'fsj': ['get']
},
{
  'kz': ['getMyOrg']
},
{
  'fkz': ['getMyOrg']
},
{
  'zr': ['getMyOrg']
},
{
  'fzr': ['getMyOrg']
},
{
  'hsz': ['getMyOrg']
},
{
  'fhsz': ['getMyOrg']
},
{
  'ys': ['getMy']
},
{
  'hs': ['getMy']
}, 'rs']

const personTmp = [{
  'self': ['base', 'getMyResume']
},
{
  'kz': ['base', 'getMyOrgResume']
},
{
  'fkz': ['base', 'getMyOrgResume']
},
{
  'zr': ['base', 'getMyOrgResume']
},
{
  'fzr': ['base', 'getMyOrgResume']
},
{
  'hsz': ['base', 'getMyOrgResume']
},
{
  'fhsz': ['base', 'getMyOrgResume']
},
{
  'ys': ['base', 'getMyResume']
},
{
  'hs': ['base', 'getMyResume']
}, 'fsj' ,'sj', 'fyz' ,'yz', 'rs']

const znfxTmp = [{
  'kz': ['base', 'getMyOrg']
},
{
  'fkz': ['base', 'getMyOrg']
},
{
  'zr': ['base', 'getMyOrg']
},
{
  'fzr': ['base', 'getMyOrg']
},
{
  'hsz': ['base', 'getMyOrg']
},
{
  'fhsz': ['base', 'getMyOrg']
}, 'fsj' ,'sj', 'fyz' ,'yz', 'rs']

const inforateTmp = [{
  'kz': ['getMyOrg']
},
{
  'fkz': ['getMyOrg']
},
{
  'zr': ['getMyOrg']
},
{
  'fzr': ['getMyOrg']
},
{
  'hsz': ['getMyOrg']
},
{
  'fhsz': ['getMyOrg']
}, 'fsj' ,'sj', 'fyz' ,'yz', 'rs']

export default [{
  'icon': 'icon-a-zu1444',
  'name': '首页',
  'url': 'person.index',
  'clientType': 'web',
  'roles': personTmp
},
{
  'code': 'zhinengfenxi',
  'icon': 'icon-a-zu1433',
  'clientType': 'web',
  'name': '智能分析',
  'roles': rolesNoSelf,
  'children': [{
    'clientType': 'web',
    'name': '智能分析',
    'url': 'worker.count',
    'roles': znfxTmp
  },
  {
    'clientType': 'web',
    'name': '员工信息完善度',
    'url': 'worker.inforate',
    'roles': inforateTmp
  },
  {
    'clientType': 'web',
    'name': '执业医护人员统计',
    'url': 'worker.worker_certificate_doctor_pra_count',
    'roles': inforateTmp
  },
  {
    'clientType': 'web',
    'name': '医护人员分析',
    'url': 'analysis.medical',
    'roles': inforateTmp
  },
  {
    'clientType': 'web',
    'name': '员工年龄分析',
    'url': 'analysis.age',
    'roles': inforateTmp
  }]
},
{
  'icon': 'icon-a-zujian215',
  'code': 'worker_center',
  'roles': allRoles,
  'clientType': 'web',
  'name': '员工中心',
  'children': [{
    'code': 'yuangongxinguanli',
    'clientType': 'web',
    'name': '员工信息管理',
    'roles': allRoles,
    'children': [{
      'clientType': 'web',
      'name': '员工信息管理',
      'url': 'worker.worker',
      'roles': tmpWorker
    },
    {
      'clientType': 'web',
      'name': '在职员工信息管理',
      'url': 'worker.worker_in',
      'roles': tmpWorker
    },
    {
      'clientType': 'web',
      'name': '非在职员工信息管理',
      'url': 'worker.worker_out',
      'roles': tmpWorker
    }]
  },
  {
    'code': 'pun_rew',
    'clientType': 'web',
    'name': '奖惩管理',
    'roles': allRoles,
    'children': [{
      'clientType': 'web',
      'name': '奖励信息管理',
      'url': 'worker.rew',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '处分信息管理',
      'url': 'worker.pun',
      'roles': tmp2
    }],
  },
  {
    'clientType': 'web',
    'name': '个人经历',
    'code': 'worker_history',
    'roles': ['self', 'rs', 'yz', 'fyz', 'sj', 'fsj', 'kz', 'fkz', 'zr', 'fzr', 'hsz', 'fhsz', 'ys', 'hs'],
    'children': [{
      'clientType': 'web',
      'name': '个人工作历史记录',
      'url': 'worker.his_work',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '教育经历',
      'url': 'worker.worker_edu',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '个人上岗历史记录',
      'url': 'worker.worker_job_title',
      'roles': tmp2
    }]
  },
  {
    'clientType': 'web',
    'name': '培训与进修管理',
    'url': 'worker.train',
    'roles': tmp1
  },
  {
    'clientType': 'web',
    'name': '授权管理',
    'url': 'worker.worker_auth',
    'roles': tmp2
  },
  {
    'clientType': 'web',
    'name': '员工信息维护',
    'url': 'worker.edit',
    'roles': ['rs']
  }]
},
{
  'icon': 'icon-a-zu1434',
  'code': 'hetongyuzhengjian',
  'roles': allRoles,
  'clientType': 'web',
  'name': '证件与合同',
  'children': [{
    'clientType': 'web',
    'name': '证书管理',
    'code': 'worker_certificate',
    'roles': allRoles,
    'type': 'menu',
    'children': [{
      'clientType': 'web',
      'name': '执业证书管理',
      'url': 'worker.worker_certificate_doctor_pra',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '专业技术职务聘任证书管理',
      'url': 'worker.worker_certificate_pro_hire',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '专业技术资格证书管理',
      'url': 'worker.worker_certificate',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '个人职业资格证书管理',
      'url': 'worker.worker_certificate_doctor_qua',
      'roles': tmp1
    },
    {
      'clientType': 'web',
      'name': '其他证书管理',
      'url': 'worker.worker_certificate_other',
      'roles': tmp1
    }],
  },
  {
    'clientType': 'web',
    'name': '合同管理',
    'roles': allRoles,
    'code': 'agreement_manage',
    'type': 'menu',
    'children': [{
      'clientType': 'web',
      'name': '劳动合同管理',
      'url': 'worker.agreement',
      'roles': tmp1
    }]
  },
  {
    'clientType': 'web',
    'name': '证件管理',
    'roles': allRoles,
    'code': 'zhengjian_manage',
    'children': [{
      'clientType': 'web',
      'name': '身份证管理',
      'url': 'worker.worker_idcard',
      'roles': tmpWorkerIdCard
    }]
  }],
},
{
  'icon': 'icon-a-zu1435',
  'clientType': 'web',
  'name': '薪资管理',
  'url': 'worker.wage',
  'roles': ['rs']
},
{
  'icon': 'icon-a-zu1436',
  'code': 'warning_center',
  'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
  'clientType': 'web',
  'name': '预警中心',
  'children': [{
    'clientType': 'web',
    'name': '岗位晋升预警',
    'url': 'worker.job_title_queue_warning',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj']
  },
  {
    'clientType': 'web',
    'name': '过期预警',
    'url': 'worker.agreement_warning',
    'roles': [{
      'yz': ['get']
    },
    {
      'fyz': ['get']
    },
    {
      'sj': ['get']
    },
    {
      'fsj': ['get']
    }, 'rs']
  },
  {
    'clientType': 'web',
    'name': '转正预警',
    'url': 'worker.agreement_turn_warning',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj']
  }]
},
// {
//   'icon': 'icon-a-4566',
//   'code': 'appraisal_center',
//   'roles': ['worker', 'dean', 'personnel', 'section_chief'],
//   'clientType': 'web',
//   'name': '考评中心',
//   'children': [{
//     'clientType': 'web',
//     'name': '创建评价与考核活动',
//     'roles': ['dean', 'personnel', 'section_chief'],
//     'url': 'appraisal.manage'
//   },
//   {
//     'clientType': 'web',
//     'name': '我的评价与考核活动',
//     'roles': ['worker', 'dean'],
//     'url': 'appraisal.my'
//   }]
// },
{
  'icon': 'icon-a-zu1365',
  'code': 'zhichenggangw',
  'roles': allRoles,
  'clientType': 'web',
  'name': '职称岗位',
  'children': [{
    'clientType': 'web',
    'name': '职称聘任排队情况',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'worker.job_title_queue'
  },
  {
    'clientType': 'web',
    'name': '医院职称聘任情况',
    'roles': tmp2,
    'url': 'worker.worker_job_current'
  },
  {
    'clientType': 'web',
    'name': '职称岗位设置',
    'roles': ['rs'],
    'url': 'base.job_title'
  },
  {
    'clientType': 'web',
    'name': '职称岗位上岗资格',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'worker.job_title_promotion'
  },
  {
    'clientType': 'web',
    'name': '医院职称聘任情况(岗位)',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'worker.job_title_current'
  }]
},
{
  'icon': 'icon-a-zu1438',
  'code': 'ask_leave_manage',
  'roles': allRoles,
  'clientType': 'web',
  'name': '请假管理',
  'children': [{
    'clientType': 'web',
    'name': '请假首页',
    'roles': tmpAskLeaveHome,
    'url': 'ask_leave.home'
  },
  {
    'clientType': 'web',
    'name': '我的请假',
    'roles': allRoles,
    'url': 'ask_leave.my'
  },
  {
    'clientType': 'web',
    'name': '请假历史记录',
    'roles': tmpAskLeave,
    'url': 'ask_leave.record'
  },
  {
    'clientType': 'web',
    'name': '请假条',
    'roles': allRoles,
    'url': 'ask_leave.apply'
  },
  {
    'clientType': 'web',
    'name': '请假条历史记录',
    'roles': ['rs'],
    'url': 'ask_leave.apply_record'
  },
  {
    'clientType': 'web',
    'name': '请假条分配',
    'roles': ['rs'],
    'url': 'ask_leave.apply_assign'
  },
  {
    'clientType': 'web',
    'name': '假条审批配置',
    'roles': ['rs'],
    'url': 'ask_leave.switch_config'
  }]
},
{
  'icon': 'icon-a-zu1441',
  'code': 'activity_record',
  'roles': ['rs'],
  'clientType': 'web',
  'name': '活动记录',
  'children': [{
    'clientType': 'web',
    'name': '活动类型',
    'roles': ['rs'],
    'url': 'activity.type'
  },
  {
    'clientType': 'web',
    'name': '活动管理',
    'roles': ['rs'],
    'url': 'activity.manage'
  },
  {
    'clientType': 'web',
    'name': '我的活动',
    'roles': ['rs', 'self'],
    'url': 'activity.my'
  },
  {
    'clientType': 'web',
    'name': '活动展示',
    'roles': ['rs'],
    'url': 'activity.show'
  }],
},
{
  'icon': 'icon-a-zu1440',
  'code': 'apply_check',
  'roles': ['rs'],
  'clientType': 'web',
  'name': '审批管理',
  'children': [{
    'clientType': 'web',
    'name': '员工信息变更审批',
    'roles': ['rs'],
    'url': 'worker.worker_edit'
  }]
},
{
  'icon': 'icon-a-zu1440',
  'code': 'doc_manage',
  'roles': allRoles,
  'clientType': 'web',
  'name': '公文管理',
  'children': [{
    'clientType': 'web',
    'roles': ['rs'],
    'name': '公文管理',
    'url': 'doc.manage'
  },
  {
    'clientType': 'web',
    'roles': allRoles,
    'name': '公文处理',
    'url': 'doc.handle'
  }]
},
{
  'url': 'evaluation.index',
  'clientType': 'web',
  'roles': tmpNdkh,
  'name': '年度考核'
},
{
  'icon': 'icon-a-zu1440',
  'code': 'count_manage',
  'clientType': 'web',
  'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
  'name': '统计管理',
  'children': [{
    'clientType': 'web',
    'name': '员工数据',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'count.base'
  },
  {
    'clientType': 'web',
    'name': '统计首页',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'count.home'
  },
  {
    'clientType': 'web',
    'name': '统计详细',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'count.count'
  }]
},
{
  'icon': 'icon-a-zu1447',
  'code': 'data_manage',
  'roles': ['dean', 'personnel', 'section_chief'],
  'clientType': 'web',
  'name': '数据管理',
  'children': [{
    'clientType': 'web',
    'name': '专业技术资格证书管理',
    'url': 'base.certificate',
    'roles': ['rs']
  },
  {
    'clientType': 'web',
    'name': '职称岗位设置',
    'url': 'base.job_title',
    'roles': ['rs']
  }],
  // },
  // {
  //   'name': '工资管理',
  //   'clientType': 'web',
  //   'icon': 'icon-a-zu1435',
  //   'code': 'performance',
  //   'children': [{
  //     'name': '财政工资',
  //     'clientType': 'web',
  //     'code': 'performance_manage',
  //     'children': [{
  //       'name': '财政工资',
  //       'clientType': 'web',
  //       'url': 'performance.worker_wage'
  //     },
  //     {
  //       'name': '员工总览',
  //       'clientType': 'web',
  //       'url': 'performance.worker_month'
  //     },
  //     {
  //       'name': '岗位总览',
  //       'clientType': 'web',
  //       'url': 'performance.worker_wage_job_title'
  //     },
  //     {
  //       'name': '薪级总览',
  //       'clientType': 'web',
  //       'url': 'performance.worker_wage_level'
  //     },
  //     {
  //       'name': '补贴工资总览',
  //       'clientType': 'web',
  //       'url': 'performance.worker_subsidy'
  //     }]
  //   },
  //   {
  //     'name': '岗位等级',
  //     'clientType': 'web',
  //     'url': 'performance.wage_job_title'
  //   },
  //   {
  //     'name': '薪级等级',
  //     'clientType': 'web',
  //     'url': 'performance.wage_level'
  //   },
  //   {
  //     'name': '职工信息',
  //     'clientType': 'web',
  //     'url': 'performance.worker'
  //   }]
}, {
  'url': 'report.nucleic.counts',
  'icon': 'icon-a-zu1433',
  'clientType': 'web',
  'roles': [],
  'name': '核酸汇总统计'
}, {
  'url': 'report.nucleic.data',
  'icon': 'icon-a-zu1447',
  'clientType': 'web',
  'roles': [],
  'name': '采样送检表导入'
}, {
  'url': 'report.nucleic.result',
  'icon': 'icon-bossdirectort',
  'clientType': 'web',
  'roles': [],
  'name': '核酸结果发布'
}, {
  'url': 'report.nucleic.print',
  'icon': 'icon-yaofangguanli3',
  'clientType': 'web',
  'roles': [],
  'name': '核酸报告打印'
}]