import {
  getFUn
} from '../auth_lib'

export default {
  ...getFUn('agreement'),
  edit: {
    funKey: 'edit',
    name: '处理',
    apis: [{
      path: '/basis/agreement/{id}',
      method: 'PUT'
    }]
  }
}