export default {
  get: {
    funKey: 'get',
    name: '查看',
    apis: [
      { method: 'GET', path: '/internalcontrol/purchase_batch/my' },
      { method: 'GET', path: '/internalcontrol/purchase_batch' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/switch/{id}' },
      { method: 'GET', path: '/ucenter/org/all' },
      { method: 'GET', path: '/ucenter/user' },
      { method: 'GET', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'GET', path: '/internalcontrol/purchase_batch/detail/by_auth' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail' },
      { method: 'PUT', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'DELETE', path: '/internalcontrol/purchase_batch/detail/{id}' },
      { method: 'POST', path: '/internalcontrol/purchase_batch/detail/switch/{id}' },
      { method: 'GET', path: '/internalcontrol/budget/detail' },
      { method: 'GET', path: '/internalcontrol/assets_type/tree' },
      { method: 'GET', path: '/internalcontrol/goods' },
      { method: 'POST', path: '/internalcontrol/goods' },
      { method: 'PUT', path: '/internalcontrol/goods/{id}' },
      { method: 'GET', path: '/internalcontrol/status_manage/switch_config' },
      { method: 'GET', path: '/internalcontrol/status_manage/config' }
    ]
  },
  viewFile: {
    funKey: 'viewFile',
    name: '查看会议材料',
    apis: [
      { method: 'GET', path: '/fms/file' },
      { method: 'GET', path: '/fms/download' }
    ]
  },
  viewDetailFile: {
    funKey: 'viewDetailFile',
    name: '查看明细附件'
  },
  uploadDetail: {
    funKey: 'uploadDetail',
    name: '上传明细附件',
    apis: [
      { method: 'PUT', path: '/internalcontrol/purchase_batch/detail/file_ids/{id}' },
    ]
  },
  delDetailFile: {
    funKey: 'delDetailFile',
    name: '删除明细附件',
    apis: [
      { method: 'PUT', path: '/internalcontrol/purchase_batch/detail/file_ids/{id}' },
    ]
  }
}